import { ComponentBase } from '~/react/utils/ComponentBase'
import { NotificationsModel } from '~/models/NotificationsModel'
import { mixpanelTrack } from '~/utils/MixpanelUtils'
import * as Constants from '~/utils/Constants'
import { PlaceManager } from '~/utils/PlaceManager'
import moment from 'moment'
import React from 'react'
import { TFunction } from 'i18next'
import DOMPurify from 'dompurify'

const SCROLL_THRESHOLD = 25

type Props = {
    closeTray: () => void
    deleteNotification: (notification: any) => void
    t: TFunction
}
type State = {
    notifications: any[]
    loading: boolean
    unseenCount: number
    actionRequiredCount: number
}

export class NotificationPopoverComponent extends ComponentBase<Props, State> {
    state: State = {
        notifications: [],
        loading: false,
        unseenCount: 0,
        actionRequiredCount: 0,
    }

    private model: NotificationsModel
    private readonly onMutate?: () => void
    private scroller?: HTMLDivElement | null

    constructor(props) {
        super(props)
        this.model = NotificationsModel.getInstance()
        this.state = {
            notifications: [],
            loading: false,
            unseenCount: this.model.unseenCount,
            actionRequiredCount: this.model.actionRequiredCount,
        }
        this.onMutate = this.model.onMutate(() =>
            this.setState({
                notifications: this.model.notifications,
                unseenCount: this.model.unseenCount,
                actionRequiredCount: this.model.actionRequiredCount,
            })
        )
    }

    componentWillUnmount() {
        if (this.onMutate) {
            this.onMutate()
        }
    }

    componentDidMount() {
        this.loadNotifications()
        this.trackViewed()
    }

    loadNotifications() {
        this.setState({ loading: true }, () => {
            void this.model
                .getNotifications()
                .finally(() => this.setState({ loading: false }))
                .then(() => this.model.seeAllNotifications())
        })
    }

    clearNotifications() {
        const { t } = this.props
        void mixpanelTrack('Mark All As Read clicked')
        this.model.clearAllNotifications().catch(() => alert(t('notifications.read_error')))
    }

    trackViewed() {
        void mixpanelTrack('Notifications Viewed', {
            'Unseen FYI Notifications': this.model.unseenCount,
            'Action Required Notifications': this.model.actionRequiredCount,
        })
    }

    static trackClicked(n) {
        void mixpanelTrack('Notification Clicked', { 'Notification Type': n.type })
    }

    static getNotificationIcon({ icon_enum: icon, overlay_enum: overlay, photo_url: photoUrl }) {
        const imgStyle = {
            ...(photoUrl && { backgroundImage: `url(${photoUrl})` }),
        }
        const iconClass = Constants.NotificationIconEnumClassMapping[icon] || ''
        const overlayClass = Constants.NotificationOverlayEnumClassMapping[overlay] || ''
        return <div className={`avatarBase avatar-size-40 ${iconClass} ${overlayClass}`} style={imgStyle} />
    }

    getActionRequiredRows() {
        return this.state.notifications
            .filter(({ action_required }) => action_required)
            .map(n => this.notificationRow(n))
    }

    getFYIRows() {
        return this.state.notifications
            .filter(({ action_required }) => !action_required)
            .map(n => this.notificationRow(n))
    }

    getLoadingRow() {
        return (
            this.state.loading && (
                <div className="notificationItem statusLoading">
                    <div className="spinning-button"> {this.props.t('loading...')}</div>
                </div>
            )
        )
    }

    getEmptyRow() {
        return (
            !this.state.loading && (
                <div className="notificationItem statusMessage">{this.props.t('notifications.empty')}</div>
            )
        )
    }

    onNotificationClick(n) {
        NotificationPopoverComponent.trackClicked(n)
        if (n.is_active) {
            void this.model.clearNotifications([n.id]).finally(() => this.doNotificationNav(n))
        } else {
            this.doNotificationNav(n)
        }
    }

    closeTray() {
        this.props.closeTray()
    }

    doNotificationNav(n) {
        const { type, nav_type, nav_object } = n
        if (nav_type === 'Employee') {
            const { full_name, public_id } = nav_object
            PlaceManager.getInstance().goToEmployee({ public_id, full_name }, true)
            this.closeTray()
        } else if (nav_type === 'Group') {
            if (nav_object) {
                const { slug_name, name } = nav_object
                if (['group_join_request', 'group_join_result'].includes(type)) {
                    // Handle GroupJoinRequest ones separately
                    const v = 'j'
                    if (type === 'group_join_request') {
                        const {
                            created_by: {
                                employee: { public_id: req },
                            },
                        } = n
                        PlaceManager.getInstance().goToGroups({ slug_name, name, v, req })
                    } else {
                        PlaceManager.getInstance().goToGroups({ slug_name, name, v })
                    }
                } else {
                    PlaceManager.getInstance().goToGroups({ slug_name, name })
                }
            } else {
                const v = type === 'community_deletion' ? 'o' : 'm'
                PlaceManager.getInstance().goToGroups({ v })
            }
            this.closeTray()
        } else if (nav_type === 'TimelineEntry') {
            const { id: tleId } = nav_object
            PlaceManager.getInstance().goToSingleTLE({ tleId }, true)
            this.closeTray()
        } else if (nav_type === 'InMemoriam') {
            const { public_id: publicId, full_name: name } = nav_object
            PlaceManager.getInstance().goToInMemoriam({ publicId, name }, true)
            this.closeTray()
        } else if (nav_type === 'SurveyToken') {
            // Surveys link out as a regular anchor tag, so all we need to do here is close the tray
            this.closeTray()
        } else if (nav_type === 'SurveyReport') {
            this.props.deleteNotification(n)
            this.closeTray()
        } else {
            // Non-SPA fallback, since we can't SPA single posts/tles
            globalThis.location.assign(n.url)
        }
    }

    onScroll() {
        if (!this.state.loading) {
            const { scrollHeight: maxScroll, scrollTop, clientHeight } = this.scroller as HTMLDivElement
            const currentScroll = scrollTop + clientHeight
            if (maxScroll - currentScroll < SCROLL_THRESHOLD) {
                // trigger load
                this.setState({ loading: true }, () => {
                    void this.model.getNextNotifications().finally(() => this.setState({ loading: false }))
                })
            }
        }
    }

    notificationRow(n: any) {
        const { t } = this.props
        let dateStr
        const m = moment(n.last_made_active || n.created_datetime)
        const now = moment()
        if (now.diff(m, 'days') > 365) {
            dateStr = t('notifications.moment_year', { date: m })
        } else if (now.diff(m, 'days') > 45) {
            dateStr = t('notifications.moment', { date: m })
        } else {
            dateStr = t('notifications.moment_from', { date: m })
        }
        const isExternalLink = n.nav_type === 'SurveyToken' || n.nav_type === 'SurveyReport'
        return (
            <a
                className={
                    'notificationItem' + (n.is_active ? ' unread' : '') + (n.action_required ? ' importantItem' : '')
                }
                href={isExternalLink ? n.url : undefined}
                target={isExternalLink ? '_blank' : undefined}
                onClick={() => {
                    this.onNotificationClick(n)
                }}
                key={n.id}
            >
                <div className="notificationIcon">{NotificationPopoverComponent.getNotificationIcon(n)}</div>
                <div className="notificationText">
                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(n.content) }}></span>
                    <div className="notificationDate">{dateStr}</div>
                </div>
            </a>
        )
    }

    render() {
        const actionRequiredRows = this.getActionRequiredRows()
        const fyiRows = this.getFYIRows()
        const showActionRequired = Boolean(
            this.state.loading ? this.state.actionRequiredCount : actionRequiredRows.length
        )
        const { t } = this.props
        return (
            <div className="notifications-popover" ref={e => (this.scroller = e)} onScroll={this.onScroll.bind(this)}>
                {showActionRequired && (
                    <React.Fragment>
                        <div className="notifications-header">
                            <h5>{t('notifications.action_required')}</h5>
                        </div>
                        <div className="notificationContainer">{actionRequiredRows}</div>
                    </React.Fragment>
                )}
                <div className="notifications-header">
                    <h5>{t('notifications.notifications')}</h5>
                    {this.state.notifications.some(
                        ({ is_active, action_required }) => is_active && !action_required
                    ) && (
                        <a className="notification-clear-all-link" onClick={this.clearNotifications.bind(this)}>
                            {t('notifications.read_all')}
                        </a>
                    )}
                </div>
                <div className="notificationContainer">
                    {fyiRows.length > 0 ? fyiRows : this.getEmptyRow()}
                    {this.getLoadingRow()}
                </div>
            </div>
        )
    }
}

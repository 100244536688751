import React, { useEffect, useMemo, useRef, useState } from 'react'
import { NotificationsModel } from '~/models/NotificationsModel'
import { NotificationPopoverComponent } from '~/react/navbar/NotificationPopoverComponent'
import { useTranslation } from 'react-i18next'
import { Popover } from 'react-bootstrap'
import { useOutsideAlerter } from '~/react/common-components/hooks/useOutsideAlerter'
import { useMutation } from '@apollo/client'
import { DeleteSurveyReportNotificationDocument } from '~/generated/graphql'

export const NotificationComponent = () => {
    const { t } = useTranslation()
    const [unseenCount, setUnseenCount] = useState<number>(0)
    const [actionRequiredCount, setActionRequiredCount] = useState<number>(0)
    const model = useMemo(() => NotificationsModel.getInstance(), [])
    const bellRef = useRef<HTMLAnchorElement>(null)
    const popoverRef = useRef<HTMLDivElement>(null)
    const { showElement: showPopover, setShowElement: setShowPopover } = useOutsideAlerter(false, popoverRef, [bellRef])

    const count = unseenCount || actionRequiredCount || 0
    const [deleteSurveyReportNotification] = useMutation(DeleteSurveyReportNotificationDocument)
    const doDeleteSurveyReportNotification = (n: any) => {
        deleteSurveyReportNotification({
            variables: { surveyReportId: n.object_id },
        }).then(
            resp => {
                if (resp?.data?.deleteSurveyReportNotification?.ok) {
                    console.log('notification deleted')
                }
            },
            () => {}
        )
    }

    useEffect(() => {
        model.onMutate(({ unseenCount, actionRequiredCount }) => {
            setUnseenCount(unseenCount)
            setActionRequiredCount(actionRequiredCount)
        })
    }, [model])

    return (
        <li className={'li-nav-notification-bell' + (count ? ' active' : '') + (actionRequiredCount ? ' urgent' : '')}>
            <a
                role="button"
                className=""
                id="navbar_notifications_icon"
                onClick={() => setShowPopover(!showPopover)}
                ref={bellRef}
                aria-haspopup="true"
                aria-expanded="false"
            >
                {count ? (
                    <div className="bell-container">
                        <div className="bell-icon" />
                        <div className="bell-count-badge">
                            {count > 99
                                ? t('notifications.count+', { count: 99 })
                                : t('notifications.count', { count })}
                        </div>
                    </div>
                ) : (
                    <div className="bell-container">
                        <div className="bell-icon" />
                    </div>
                )}
            </a>
            <div className={'popover-container'}>
                {showPopover && (
                    <Popover ref={popoverRef} placement={'bottom'}>
                        <NotificationPopoverComponent
                            closeTray={() => setShowPopover(false)}
                            deleteNotification={doDeleteSurveyReportNotification}
                            t={t}
                        />
                    </Popover>
                )}
            </div>
        </li>
    )
}

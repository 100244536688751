export const OWPageType = {
    UNKNOWN: 1,
    FULL_REACT_BASED: 2,
    PAGE_VIEW_CONTROLLER_BASED: 3,
    JQUERY_BASED: 4,
}

export const GroupTypes = {
    COMMUNITY_TYPE: 'COMMUNITY',
    DLIST_TYPE: 'DISTRIBUTION_LIST',
}

export const GroupSubTypes = {
    SOCIAL_TYPE: 'SOCIAL',
    LOCAL_TYPE: 'LOCAL',
    IN_MEMORIAM: 'IN_MEMORIAM',
    AUTO_DLIST_TYPE: 'AUTO_DLIST',
    CUSTOM_DLIST_TYPE: 'CUSTOM_DLIST',
}

export enum LocationTypes {
    WORKPLACE_OFFICE = 'OFFICE',
    WORKPLACE_REMOTE = 'REMOTE',
    WORKPLACE_CUSTOMER_SITE = 'CUSTOMER_SITE',
    WORKPLACE_UNKNOWN = 'UNKNOWN',
    NONE = 'NONE',
}

export enum PrimaryLocationTypes {
    OFFICE = 'OFFICE',
    HOME = 'HOME',
    UNKNOWN = 'UNKNOWN',
}

export const ServiceAccountTypes = {
    OAUTH: 'OAUTH',
    TOKEN: 'TOKEN',
}

export const DListProviderIds = {
    GOOGLE: 'GOOGLE',
    OFFICE_365: 'O365',
}

export const ManagerFeedbackSurveyStatus = {
    PENDING: 'PENDING',
    OPEN: 'OPEN',
    CANCELLED: 'CANCELLED',
    DONE: 'DONE',
}

// This matches the definitions in models/settings.py and the definitions in models/feature.py
export const Features = {
    /* TODO: OW-9272 remove after APRIL fools */
    APRIL_FOOLS: 'APRIL_FOOLS',
    /* TODO: end remove */
    COMMUNITIES_ENABLED: 'communities_enabled',
    COMPANY_POSTS_ENABLED: 'company_posts_enabled',
    DISABLE_MOBILE_APP: 'disable_mobile_app',
    DISABLE_PRELOADING: 'disable_preloading',
    DISABLE_WIKI_OFFICE_EDITS: 'disable_wiki_office_edits',
    DOTTED_LINES_ENABLED: 'dotted_lines_enabled',
    EMPLOYEES_ONLY_DLISTS: 'employees_only_dlists',
    GUEST_PASSES_ENABLED: 'guest_passes_enabled',
    HIDE_NARRATIVES: 'hide_narratives',
    HIDE_NEWS_FEED: 'hide_news_feed',
    HIDE_NOTIFICATION_TRAY: 'hide_notification_tray',
    HIDE_ORG_FACET: 'hide_org_facet',
    HIDE_PROFILE_STRENGTH: 'hide_profile_strength',
    JOB_POSTS_ENABLED: 'job_posts_enabled',
    LET_IT_SNOW_HOMEPAGE: 'let_it_snow_homepage',
    PRELAUNCH_MODE: 'prelaunch_mode',
    PROFILE_CELEBRATIONS_ENABLED: 'profile_celebrations_enabled',
    SEARCH_MAP_VIEW_ENABLED: 'search_map_view_enabled',
    SECONDARY_ADDRESS_EXPORT_ENABLED: 'secondary_address_export_enabled',
    SHOW_DLISTS_HOMEPAGE: 'show_dlists_homepage',
    SHOW_DLISTS_PROFILE_CARD: 'show_dlists_profile_card',
    SHOW_HIDE_EMAIL_ON_PROFILE: 'show_hide_email_on_profile',
    SHOW_TEAMS_DEMO: 'show_teams_demo',
    SHOW_WHO_CAN_POST_TO_OWNERS: 'show_who_can_post_to_owners',
    SHOW_SEARCH_STYLE_HOMEPAGE: 'show_search_style_homepage',
    SHOW_SKILL_CATEGORIES: 'show_skill_categories',
    SPREADSHEET_PROFILE_PHOTOS: 'spreadsheet_profile_photos',
    SYNC_VACATION: 'sync_vacation',
    TAGS_ENABLED: 'tags_enabled',
    TRANSFER_PORTAL_ENABLED: 'transfer_portal_enabled',
    WIKI_PROFILE_EDITS_ENABLED: 'wiki_profile_edits_enabled',
    WORK_ANYWHERE_ENABLED: 'work_anywhere_enabled',
    SYNC_MATTERMOST: 'sync_mattermost',
    SESSION_TIMEOUT_ENABLED: 'session_timeout_enabled',
    TIME_OFF_ENABLED: 'time_off_enabled',
}

// Replicated in company/serializers.py:CompanySerializer.get_feature_flags
export const CompanyFeaturePropertyMap = {
    configuration_mode_enabled: { flag: Features.PRELAUNCH_MODE },
    company_posts_enabled: { flag: Features.COMPANY_POSTS_ENABLED },
    dotted_lines_enabled: { flag: Features.DOTTED_LINES_ENABLED },
    employee_only_lists_enabled: {
        flag: Features.EMPLOYEES_ONLY_DLISTS,
    },
    guest_passes_enabled: { flag: Features.GUEST_PASSES_ENABLED },
    hide_company_feed: { flag: Features.HIDE_NEWS_FEED },
    job_posts_enabled: { flag: Features.JOB_POSTS_ENABLED },
    let_it_snow_homepage: { flag: Features.LET_IT_SNOW_HOMEPAGE },
    profile_celebrations_enabled: {
        flag: Features.PROFILE_CELEBRATIONS_ENABLED,
    },
    show_who_can_post_to_owners: {
        flag: Features.SHOW_WHO_CAN_POST_TO_OWNERS,
    },
    social_groups_enabled: { flag: Features.COMMUNITIES_ENABLED },
    sync_vacation: { flag: Features.SYNC_VACATION },
    tags_enabled: { flag: Features.TAGS_ENABLED },
    transfer_portal_enabled: {
        flag: Features.TRANSFER_PORTAL_ENABLED,
    },
    wiki_office_edits_disabled: {
        flag: Features.DISABLE_WIKI_OFFICE_EDITS,
    },
    wiki_profile_edits_enabled: {
        flag: Features.WIKI_PROFILE_EDITS_ENABLED,
    },
    work_anywhere_enabled: { flag: Features.WORK_ANYWHERE_ENABLED },
    sync_mattermost: { flag: Features.SYNC_MATTERMOST },
}
export const CompanyFeatureFlagMap = Object.keys(CompanyFeaturePropertyMap)
    .map(k => ({
        [CompanyFeaturePropertyMap[k].flag]: { property: k },
    }))
    .reduce((acc, obj) => Object.assign(acc, obj), {})

// This matches the definitions in notifications/py
export const NotificationIconEnumClassMapping = {
    user_photo: 'ico-user-photo',
    anniversary: 'ico-anniversary',
    office_dlist: 'ico-office-dlist',
    custom_dlist: 'ico-dlist-custom',
    community: 'ico-community',
    pdf: 'ico-pdf avatar-not-rounded',
    xlsx: 'ico-xlsx avatar-not-rounded',
}

// This matches the definitions in notifications/py
export const NotificationOverlayEnumClassMapping = {
    is_edit: 'withSecondaryIcon isEdit',
    is_comment: 'withSecondaryIcon isComment',
    is_post: 'withSecondaryIcon isPost',
    anniversary: 'withSecondaryIcon isAnniversary',
}

export const FeedTabs = {
    posts: 'posts',
    jobs: 'jobs',
    reportingOrTitleChange: 'reporting_or_title_change',
    employeeAddRemove: 'employee_add_remove',
}
export const FeedTabsWithoutJobs = {
    all: 'all',
    posts: 'posts',
    reportingStructure: 'reporting_structure',
    titleChange: 'titleChange',
    employeeAddRemove: 'employee_add_remove',
}

export const TimelineEntryTypes = {
    postText: 'post_text',
    postPhoto: 'post_photo',
    jobPost: 'job_post',
    reportingStructure: 'reporting_structure',
    titleChange: 'title_change',
    employeeAdd: 'employee_add',
    employeeRemove: 'employee_remove',
    postVideo: 'post_video',
}

export const Languages = {
    en: {
        label: 'English',
        icon: '🇺🇸',
    },
    fr: {
        label: 'Français',
        icon: '🇫🇷',
    },
    es: {
        label: 'Español',
        icon: '🇪🇸',
    },
    de: {
        label: 'Deutsche',
        icon: '🇩🇪',
    },
    nl: {
        label: 'Nederlands',
        icon: '🇳🇱',
    },
}

// Keep this in sync with IDPType
export const IdentityProviders = {
    google: 'google',
    microsoft: 'microsoft',
    okta: 'okta',
    pingfed: 'pingfed',
}

export const AuditColumns = {
    object: 'Object',
    name: 'Name',
    date: 'Modified Date',
    modifier: 'Modified By',
    entry: 'Entry',
    action: 'Action',
    fieldName: 'Field Name',
    field: 'Field',
    old: 'Old Value',
    new: 'New Value',
}

export enum QueryParams {
    ordering = 'ordering',
    search = 'search',
    secondary = 'se',
    page = 'page',
    flat = 'flat',
    mode = 'm',
    fromInstantSearch = 'fis',
    tab = 'v',
    filterId = 'f',
    isInactive = 'isInactive',
    isContractor = 'isContractor',
    isExternal = 'isExternal',

    queryString = 'q',
    skillSlug = 'sks',
    skillName = 'skn',
    areaName = 'an',
    productName = 'pn',
    mapView = 'mv',
    pageSize = 'pageSize',
    transferPortalMode = 'tp',
    sortType = 'sortType',
    cityId = 'city',
    cityName = 'cityname',
    mapCenter = 'mc',
    mapZoom = 'mz',
}

export enum ProfileHistoryFilterTypes {
    ALL = 'ALL',
    TITLE_MANAGER_RP = 'TITLE_MANAGER_RP',
}
